require('./bootstrap');

$.datetimepicker.setLocale('pt-BR');

/*
document.addEventListener("DOMContentLoaded", function(){

    el_autohide = document.querySelector('.autohide');

    // add padding-top to bady (if necessary)
    navbar_height = document.querySelector('.header').offsetHeight;
    document.body.style.paddingTop = navbar_height + 'px';

    if(el_autohide){
        var last_scroll_top = 0;
        window.addEventListener('scroll', function() {
            let scroll_top = window.scrollY;
            if(scroll_top < last_scroll_top) {
                el_autohide.classList.remove('scrolled-down');
                el_autohide.classList.add('scrolled-up');
            }
            else {
                el_autohide.classList.remove('scrolled-up');
                el_autohide.classList.add('scrolled-down');
            }
            last_scroll_top = scroll_top;
        });
        // window.addEventListener
    }
    // if

});
*/

$(document).ready(function() {

    $('.hour').mask('00:00');
    $('.date').mask('00/00/0000');
    $('.datehour').mask('00/00/0000 00:00');
    $('.cpf').mask('000.000.000-00', {reverse: true});
    $('.cnpj').mask('00.000.000/0000-00', {reverse: true});
    $('.money').mask('000.000.000.000.000,00', {reverse: true});
    $('.money2').mask("#.##0,00", {reverse: true});
    $('.number').mask("0###", {reverse: true});
    $('.instagram').mask('@YYYZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ', {
        translation: {
            'Y': {
                pattern: /[a-zA-Z0-9._-]/
            },
            'Z': {
                pattern: /[a-zA-Z0-9._-]/, optional: true
            }
        }
    });
    $('.money-negative').mask('#.##0,00', {
        reverse: true,
        translation: {
            '#': {
                pattern: /-|\d/,
                recursive: true
            }
        },
        onChange: function(value, e) {
            const target = e.target,
                position = target.selectionStart; // Capture initial position
            target.value = value.replace(/(?!^)-/g, '').replace(/^\./, '').replace(/^-\./, '-').replace(/^,/, '').replace(/^-,/, '-');
            target.selectionEnd = position; // Set the cursor back to the initial position.
        }
    });

    $('[data-toggle="tooltip"]').tooltip();

    $('.hour').datetimepicker({
        datepicker: false,
        format: 'H:i',
        step: 15,
        minTime: '06:00',
        maxTime: '22:15',
    });

    $('.date').datetimepicker({
        timepicker: false,
        format: 'd/m/Y',
    });

    $('.datehour').datetimepicker({
        format: 'd/m/Y H:i',
        step: 15,
        minTime: '06:00',
        maxTime: '22:15',
    });

    const maskBehavior = function (val) {
            val = val.replace(/\D/g, '');
            if (val.substr(0, 4) === '0800' || val.substr(0, 4) === '0300') {
                return '0000 000 0000';
            }
            return val.length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        options = {onKeyPress: function(val, e, field, options) {
                field.mask(maskBehavior.apply({}, arguments), options);
            }
        };
    $('.phone').mask(maskBehavior, options);

    $(document).on('keydown', '.cpf-cnpj', function (e) {
        const digit = e.key.replace(/\D/g, '');
        const value = $(this).val().replace(/\D/g, '');
        const size = value.concat(digit).length;
        $(this).mask((size <= 11) ? '000.000.000-00' : '00.000.000/0000-00');
    });

    $('.zipcode').mask('00000-000')
        .keyup(function(e) {
            if ($(this).val().replace(/\D/g, '').length >= 8) {
                $.getJSON('https://viacep.com.br/ws/'+ $(this).val() +'/json', function(data) {
                    if (!('erro' in data)) {
                        $('.street').val(data.logradouro);
                        $('.neighborhood').val(data.bairro);
                        $('.city').val(data.localidade);
                        $('.state').val(data.uf);
                    }
                });
            }
        });

    $('#lead_status').on('change', function() {

        $('#lead_demonstrations_select').hide();
        $('#lead_link_input').hide();
        $('#lead_status_chk').hide();
        $('#alert_lead_removal').hide();
        $('.alert-lead-removal-observation').prop('required', false);
        $('.alert-lead-removal-observation-label').removeClass('required');

        if( this.value === 'not_interested' || this.value === 'spam' || this.value === 'invalid_contact' ) {
            $('#alert_lead_removal').show();
            $('.alert-lead-removal-observation').prop('required', true);
            $('.alert-lead-removal-observation-label').addClass('required');
        }
        if( this.value === 'demonstration' || this.value === 'demonstrated' ) {
            $('#lead_link_input').show();
        }
        if( this.value === 'demonstration' ) {
            $('#lead_demonstrations_select').show();
        }
        if( this.value === 'assigned' ) {
            $('#lead_status_chk').show();
        }

    });

    $('#demonstration_parent_id').on('change', function() {
        $('#lead_date_at').show();
        if( this.value ) {
            $('#lead_date_at input').val('');
            $('#lead_date_at').hide();
        }
    });

    $('.money, .money-negative').on('change', function() {
        if( !this.value.includes(',') ) {
            this.value = this.value + ',00';
        }
    });

    $('.money-negative').each(function( index ) {
        $( this ).val( $( this ).val().replace(/(?!^)-/g, '').replace(/^\./, '').replace(/^-\./, '-').replace(/^,/, '').replace(/^-,/, '-') );
    });

    const hash = window.location.hash;
    if (hash) {
        const element = $(hash + ' .accordion-button');
        if (element.length) {
            element.trigger('click');
            if (hash.includes('headingRoot')) {
                $('#nav-002-tab').trigger('click');
            } else if (hash.includes('headingGuidelines')) {
                $('#nav-004-tab').trigger('click');
            } else if (hash.includes('headingTestimonials')) {
                $('#nav-007-tab').trigger('click');
            } else if (hash.includes('headingMessages')) {
                $('#nav-003-tab').trigger('click');
            } else if (hash.includes('headingDeals')) {
                $('#nav-005-tab').trigger('click');
            } else if (hash.includes('headingDemonstrations')) {
                $('#nav-006-tab').trigger('click');
            } else if (hash.includes('heading')) {
                $('#nav-001-tab').trigger('click');
            }
        }
    }

     window.setDateAt = function(weeks, lastDate) {

        const parts = lastDate.split(" ");
        const dateParts = parts[0].split("/");
        const timeParts = parts[1].split(":");
        const day = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]) - 1;
        const year = parseInt(dateParts[2]);
        const hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);

        const inputDate = new Date(year, month, day, hours, minutes);

        inputDate.setDate(inputDate.getDate() + (weeks * 7));

        const newDay = inputDate.getDate().toString().padStart(2, "0");
        const newMonth = (inputDate.getMonth() + 1).toString().padStart(2, "0");
        const newYear = inputDate.getFullYear();
        const newHours = inputDate.getHours().toString().padStart(2, "0");
        const newMinutes = inputDate.getMinutes().toString().padStart(2, "0");

        const newDateStr = `${newDay}/${newMonth}/${newYear} ${newHours}:${newMinutes}`;

        $('#date_at').val(newDateStr);
    }

});
